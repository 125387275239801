<template>
    <div>
      <v-card>
        <v-tabs v-model="tab" fixed-tabs background-color="green" dark>
          <v-tabs-slider color="#086318"></v-tabs-slider>
            <v-tab :disabled="disabledReceptor">RECEPTOR</v-tab>
            <v-tab :disabled="enableOrgon">DADOS DO ORGÃO</v-tab>
            <!-- <v-tab>DADOS DO TRANSPLANTE</v-tab> -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <!-- alterei o ref -->
                <v-form @submit.prevent="editReceptor" ref="form">
                  <v-container>
                    <v-row>
                      <h3 row>Informações Básicas</h3>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="Nome" 
                        placeholder="Nome completo" 
                        outlined
                        v-model="receptorForm.name" 
                        dense></v-text-field>
                      </v-col>
  
                      <v-col class="d-flex" cols="12" sm="2">
                        <v-text-field
                        color="#086318" 
                        label="RGCT" 
                        :rules="formRules" 
                        placeholder="Apenas Números" 
                        outlined
                        maxlength="14" 
                        v-mask="'##############'"
                        v-model="receptorForm.rgct" 
                        dense></v-text-field>
                      </v-col>
  
                      <v-col class="d-flex" cols="12" sm="1">
                        <v-text-field
                        color="#086318" 
                        label="Idade" 
                        :rules="formRules" 
                        placeholder="Apenas Números" 
                        outlined
                        maxlength="2" 
                        v-mask="'##'"
                        v-model="receptorForm.age" 
                        dense></v-text-field>
                      </v-col>
  
                      <v-col class="d-flex" cols="12" sm="2">
                       <v-select 
                        :items="sex"
                        :rules="formRules"
                        color="#086318" 
                        v-model="receptorForm.sex_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="Gênero">
                        </v-select>
                      </v-col>
                    </v-row>
  
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="3" v-if="this.$store.state.profile_id == 1">
                        <v-select 
                        :items="hospital"
                        :rules="formRules"
                        color="#086318" 
                        v-model="receptorForm.hospital_id"
                        item-value="id"
                        item-text="hospital"  
                        dense 
                        outlined 
                        label="Hospital Transplantador">
                        </v-select>
                      </v-col>
  
                      <v-col class="d-flex" cols="12" sm="5">
                        <v-text-field 
                        color="#086318" 
                        label="Motivo do transplante" 
                        placeholder="Doença que levou ao transplante"
                        item-text="cnes"
                        outlined
                        dense
                        :rules="formRules"
                        v-model="receptorForm.transplantation_reason" 
                        ></v-text-field>
                      </v-col>
                    </v-row>
  
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="4" v-if="vue.realized == 'true'">
                        <v-radio-group 
                        v-model="vue.realized"
                        :rules="formRules"
                        disabled>
  
                          <p>Transplante Realizado?</p>
  
                          <v-radio label="Sim"
                           value="true" 
                           color="#086318"
                           ></v-radio>
  
                          <v-radio label="Não"
                           value="false" 
                           color="#086318"
                           ></v-radio>
                        </v-radio-group>
  
                      </v-col>
                    </v-row>  
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="2" v-if="vue.realized == 'true'">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="Data do Transplante"
                        type="date" 
                        outlined 
                        v-model="receptorForm.transplantation_date"
                        dense></v-text-field>
                      </v-col>
  
                      <v-col class="d-flex" cols="12" sm="2" v-if="vue.realized == 'true'">
                        <v-text-field 
                        color="#086318" 
                        label="Hora (00:00)" 
                        placeholder="Hora do Transplante"
                        item-text="hospitalSelected.cnes"
                        outlined
                        maxlength="5" 
                        v-mask="'##:##'"
                        :rules="formRules"
                        dense
                        v-model="receptorForm.transplantation_hours" 
                        ></v-text-field>
                      </v-col>
  
                      <v-col class="d-flex" cols="12" sm="4" v-if="vue.realized == 'true'">
                        <v-select 
                        :items="team"
                        :rules="formRules"
                        color="#086318" 
                        v-model="receptorForm.team_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="Equipe médica responsável">
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4" md="8" v-if="vue.realized == 'true'">
                          <v-text-field 
                          color="#086318"
                          placeholder="Evolução do paciente pós transplante"
                          item-text="hospitalSelected.cnes"
                          outlined
                          dense
                          v-model="receptorForm.receptor_evolution" 
                          ></v-text-field>
                      </v-col>
                    </v-row>
                  <p class="space-personalized"></p>
                    <div class="buttons">
                      <v-row>
                        <v-btn class="rounded-lg" type="submit" elevation="0" @click="editReceptor()" color="green">
                          <span class="textBtn"> Atualizar </span>
                        </v-btn>
                        <div class="spacediferent"></div>
                        <v-btn class="rounded-lg" elevation="0" @click="$router.push('/receptor')" color="grey">
                          <span class="textBtn"> Voltar</span>
                        </v-btn>
                      </v-row>
                    </div>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
  
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-form @submit.prevent="addOrgon" ref="form">
                    <div>
                      <v-row>
                      <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                          :items="orgon"
                          :rules="formRules"
                          color="#086318" 
                          v-model="orgonForm.orgon_id"
                          item-value="id"
                          item-text="name"  
                          dense 
                          outlined 
                          label="Orgão Transplantado">
                          </v-select>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                          :items="laterality"
                          :rules="formRules"
                          color="#086318" 
                          v-model="orgonForm.laterality_id"
                          item-value="id"
                          item-text="name"  
                          dense 
                          outlined 
                          label="Lateralidade">
                          </v-select>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="4">
                        <v-tooltip right color="blue">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-1"  small fab dark color="blue" type="submit" v-bind="attrs" v-on="on" @click="addOrgon()">
                              <v-icon dark>
                                mdi-plus
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Inserir orgão</span>
                        </v-tooltip>
                    </v-col>
                    </v-row>
                  </div>
  
                  <template>
                    <v-data-table
                      :headers="headers"
                      :items="orgonTable"
                      :items-per-page="10"
                      class="elevation-1"
                    >
                      <template v-slot:item.actions="{ item }">
                          <v-icon 
                            size="20"
                            class="icon"
                            color="red" 
                            align-item="center"
                            @click="deleteOrgon(item)">
                            mdi-delete
                          </v-icon>
                      </template>
                    </v-data-table>
                  </template>
  
                  <p class="space-personalized"></p>
                    <div class="buttons">
                      <v-row>
                        <div class="spacediferent"></div>
                        <v-btn class="rounded-lg" @click="$router.push('/receptor')" elevation="0" color="grey">
                          <span class="textBtn"> Sair </span>
                        </v-btn>
                      </v-row>
                    </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
  
      <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
    </v-snackbar>
    </div>
  </template>  
  <script>
  
  import axios from "@/axios";
  import FormatDate from "@/utils/FormatDate"
  // import { isArgumentsObject } from "util/types";
  
  export default {
    data() {
      return {
        headers: [
            {
              text: "Orgão",
              value: "orgon.name",
              align: "center",
            },
            {
              text: "Lateralidade",
              value: "laterality.name",
              align: "center",
            },
            {
              text: "Ação",
              value: "actions",
              sortable: false,
              align: "center",
            }
        ],
        tab: null,
        page: 1,
        totalUsers: 0,
        numberOfPages: 0,
        passengers: [],
        loading: true,
        enableOrgon: false,
        disabledReceptor: false,
        enableRegistration: true,
        options: {},
        sortBy: "name",
        sex_id: '',
        identified: '',
        donor_status_id: '',
        orgon:[],
        objReceptor: "",
        lateralityForm: [],
        receptorData: {
  
        },
        orgonForm: {
          receiver_id: ''
        },
        orgon_id: '',
        disabled: true,
        receiverId: null,
        message: "",
        snackbar: false,
        loadingBTN: false,
        hospitalSelected: "",
        exibirMensagem: false,
        messageError: "",
        receptorForm: {
          unity_id: ''
        },
        laterality: [],
        sex:[],
        team: [],
        hospital:[],
        countries:[],
        state:[],
        orgonTable:[],
        vue: {
          realized: null,
        },
        formRules: [
          (v) => !!v || "Este campo é obrigatório",
        ],
      };
    },
    
    created() {
        
    this.$route.query.item.hospital_id = parseInt(this.$route.query.item.hospital_id)
    this.$route.query.item.sex_id = parseInt(this.$route.query.item.sex_id)
    this.$route.query.item.team_id = parseInt(this.$route.query.item.team_id)
    this.$route.query.item.transplantation_date = FormatDate.dataAtualFormatada(this.$route.query.item.transplantation_date)
    this.receptorForm = {...this.$route.query.item}

    if(this.receptorForm.team_id){
        this.vue.realized = 'true'
    }else{
        this.vue.realized = 'false'
    }

      axios.get("/receiver_orgon").then((response) => {
        this.receiver_id = this.$route.query.item.id
        this.orgonTable = response.data.data;
        this.orgonTable = this.orgonTable.filter((x) => x.receiver_id == this.receiver_id)
      })

      axios.get("/unities").then((response) => {
        this.hospital = response.data.data;
      });
  
      axios.get("/sex").then((response) => {
        this.sex = response.data.data;
      });
  
      axios.get("/state").then((response) => {
        this.state = response.data.data;
      });
      
      axios.get("/countries").then((response) => {
        this.countries = response.data.data;
      });
  
      axios.get("/orgon").then((response) => {
        this.orgon = response.data.data;
      });
  
      axios.get("/laterality").then((response) => {
        this.laterality = response.data.data;
      });
  
      axios.get("/teams").then((response) => {
        this.team = response.data.data;
      });
  
    },
  
    watch: {
      sortBy: {
        handler() {
  
        }
      },
  
      options: {
        handler() {
          this.indoDataTable();
        },
      },
      deep: true,
    },
  
    methods: {
  
      //Carrega as coisas ao acessar a tela
      indoDataTable() {
        this.loading = true;
      },
  
      openCreateReceptor() {
        this.dialog = true;
      },
  
      clear() {
        this.receptorForm = ""
        this.realized = ""
      },
   
      editReceptor(){
      if(this.$refs.form.validate() && this.vue.realized == 'true'){
        const novoReceptor = this.receptorForm 
        axios
          .put(`/receiver/${novoReceptor.id}`, novoReceptor)
          .then((response) => {
            this.indoDataTable();
            this.message = "Registro atualizado";
            this.receiverId = response.data.data
            this.snackbar = true
            this.disabledReceptor = false
            this.tab = 1
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      } else if(this.$refs.form.validate() && this.vue.realized == 'false'){
        const novoReceptor = this.receptorForm
        axios
          .put(`/receiver/${novoReceptor.id}`, novoReceptor)
          .then((response) => {
            this.indoDataTable();
            this.message = "Receptor Cadastrado!";
            this.receiverId = response.data.data
            this.snackbar = true
            this.$router.push({ name: "Receptores"});
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      }
    },

      addOrgon(){
      if (this.$refs.form.validate()){
        this.orgonForm.receiver_id = this.$route.query.item.id
        const orgon = this.orgonForm
        axios
        .post(`/receiver_orgon`, orgon).then(() => {
            this.message = "Orgão Inserido!";
            this.enableRegistration = false;
            this.snackbar = true;
            this.orgonForm.orgon_id = "";
            this.orgonForm.laterality_id = "";
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          }).finally(() => {
            axios.get("/receiver_orgon").then((response) => {
              this.receiver_id = this.$route.query.item.id
                this.orgonTable = "";
                this.orgonTable = response.data.data;
                this.orgonTable = this.orgonTable.filter((x) => x.receiver_id == this.receiver_id);
              });
          });
      }
    },
  
      deleteOrgon(item){
      const orgonId = item.id
      axios.delete(`/receiver_orgon/${orgonId}`).then((response) => {
          this.loading = true;
          this.message = response.data.message;
          this.snackbar = true;
        });
    },
  
      mounted() {
        this.indoDataTable();
      },
    }
  
  };
  </script>
  
  <style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }
  
  .space {
    width: 15px;
  }
  
  .spacediferent {
    width: 7px;
  }
  
  .space-personalized {
    padding: 15px;
  }
  
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
        #27ae60,
        #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
        #ee106d,
        #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  
  .icon {}
  
  .banner>h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>
  